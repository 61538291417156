














































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import StickyTemplateWindow from "./StickyTemplateWindow.vue";
import {NorthStar} from "@/typescript/types";
import NorthStarService from "@/NorthStarService";
import OnboardingVideoTutorial from "./OnboardingVideoTutorial.vue"
import CuratedGoalsSelector from "@/components/templates/CuratedGoalsSelector.vue"



@Component({
  components: {StickyTemplateWindow},
  
})
export default class AddANorthstarOrTemplate extends Vue {

  isActive: boolean = false;
  templateWindowIsActive: boolean = false;
  submitted: boolean = false;
  northstarData: NorthStar | null = null;
  selectedColor: string | null = null;
  
  selectedNorthStar: NorthStar | null = null;
  createNorthStarInput: string | null = null;
  service: NorthStarService | null = null;
  startDate: Date | null = null;
  dueDate: Date | null = null;
  endDate: string | null = null;
  
  colors: Array<string> = [
    '#773f75',
    '#bd5fb4',
    '#374ea9',
    '#776015',
    '#7b7343',
    '#316863',
    '#2e825f',
    '#654C4F',
    '#5B507A',
    '#546356',
    '#1F4730',
    '#642B2C',
    '#2E294E',
    '#820263',
    '#64024C',
  ];

  preSetColors: Array<string> = [
    '#773F75',
    '#4A677D',
    '#4E6068',
    '#773F75',
    '#5B507A',
    '#7B7343',
    '#654C4F',
    '#316863',
  ];


  @Prop({default: null, required: false})
  startDateFromSprint!: Date;

  @Prop({default: null, required: false})
  dueDateFromSprint!: Date;

  get origin() { 
    return this.$store.state.board ? 'board' : 'workspace'
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get finalColor() {
    if(this.selectedColor) {
      return this.selectedColor;
    } else if(this.$props.origin == 'board' && this.suggestColorFromNsCount) {
      return this.suggestColorFromNsCount;
    } else {
      return null;
    }
  }

  

  get suggestColorFromNsCount() {
    let count = this.$store.state.board.northStars.length;
    if(count === 0) {
      return '#773f75';
    } else if(count === 1) {
      return '#4a677d';
    } else if(count === 2) {
      return '#7b7343';
    } else {
      return null;
    }
  }

  get onboardingStep() {
    return this.$store.state.user_onboarding_number;
  }

  get displayOnboardingWindow() {
    return this.$store.state.display_onboarding_window;
  }

  handleClickOutside(event: MouseEvent) {
    const inlineBox = this.$el.querySelector('.window_section');
 
 
     
    if ((inlineBox && !inlineBox.contains(event.target as Node))) {
     
      this.templateWindowIsActive = false;
     
     
    }
  }

  removeClickListener() {
    document.removeEventListener('click', this.handleClickOutside);
  }


  addClickListener() {
    document.addEventListener('click', this.handleClickOutside);
  }

  toggleWindow() { 
    this.isActive = !this.isActive;
    this.focusInput();
  }

  toggleTemplateWindow(event: MouseEvent) {
   
    event.stopPropagation();
   
    this.openCuratedGoals();
    
  }

  hideOnboarding() {
    this.$store.commit('set_display_onboarding_window', false);
  }


  closeAll() {
    this.templateWindowIsActive = false;
    this.isActive = false;
  }

  focusInput() {
    this.$nextTick(function(){
      if(this.$refs.oInput) {
        //@ts-ignore ToDo: fix type issue
        this.$refs.oInput.focus();

        
      }
    });
  }

  reset() {
    this.createNorthStarInput = null;
    this.selectedColor = null;
    this.submitted = false;
   
  }

  setNextColor() {
    const northStarCount = this.$store.state.board?.northStars.length || 0;

    if (northStarCount <= 7) {
      this.selectedColor = this.preSetColors[northStarCount % this.preSetColors.length];
    } else {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      this.selectedColor = this.colors[randomIndex];
    }
  }

  createOrAddNorthStar(event: MouseEvent): void {
    event.stopPropagation();
    this.setNextColor();
    this.$gtag.event('pmb_create_ns');
    this.submitted = true;

   

    if(!this.selectedColor) {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      this.selectedColor = this.colors[randomIndex];
      
    }
    if (this.selectedNorthStar) {
      this.addNorthStar(this.selectedNorthStar)
      return;
    }
    
    if (this.origin === 'board') {
      this.service?.createNorthStar(this.workspace, this.board, this.createNorthStarInput!, this.$props.startDate, this.$props.startDate , this.finalColor).then((result: any) => {
        this.$emit('completed', true)
        
        this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
        this.$store.commit('set_newly_created_northstar_in_board', result.data.createNorthStar.id);
        this.$gtag.event('pembio_create_north_star');
        this.reset();
        this.$events.fire('new_northstar_created', result.data.createNorthStar)
       
        if(this.$props.inModal) {
          //@ts-ignore
          this.$parent.close();
        }

      })
    } else if(this.origin == 'workspace') {
      this.service?.createNorthStarWithoutBoard(this.workspace, this.createNorthStarInput!, null, this.$props.startDate, this.finalColor).then((result: any) => {
        this.$emit('completed', true)
        this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
        this.$events.fire('new_northstar_created', result.data.createNorthStar)
        
        this.$gtag.event('pembio_create_north_star');
        if(this.$props.inModal) {
          //@ts-ignore
          this.$parent.close();
        }
      })
    }
    this.createNorthStarInput = null;
    this.$emit('northstar-added', true);
    this.$store.commit('set_add_goal_open', false);

    
    
    this.closeAll() 
  }

  addNorthStar(northStar: NorthStar): void {
    this.selectedNorthStar = null;
    // @ts-ignore
    let northStarBoards = [...northStar.boards,this.board];
    this.service?.addBoardToNorthStar(northStarBoards, northStar).then(() => {
      this.$emit('completed', true)
      if(this.$props.inModal) {
        //@ts-ignore
        this.$parent.close();
      }
    })
  }

  setStartDate(): void {
    /**
     * Set tomorrow as due datetime
     * */
    let today = new Date();
    if (this.startDateFromSprint) {
      this.startDate = this.startDateFromSprint;
    } else {
      this.startDate = today;
    }
  }


  openTutorialVideo() {
    this.$buefy.modal.open({
      component: OnboardingVideoTutorial,
      width: '1150px',
      canCancel: true,
      parent: this,
      customClass: 'video-tutorial'
    });
    this.$store.commit('set_user_onboarding_number', null);
    this.$gtag.event('user_opened_onboarding_video');
  }

  openCuratedGoals() {
    this.$buefy.modal.open({
      component: CuratedGoalsSelector,
      width: '700px',
      parent: this,
      customClass: 'curated_modal'
    });
  }

  mounted() {
    this.$gtag.event('pmb_app_guided_create_objective_mounted');
    this.setStartDate()
    this.setNextColor()
   
    if(this.$props.northStar) {
     
      if(this.$props.northStar.objective_suggestions && this.$props.northStar.objective_suggestions.length > 0) {
        this.northstarData = this.$props.northStar;
      }
      // else{
      //   this.northstarWithSuggestions();
      //
      // }
    }

    

    


    if(this.dueDateFromSprint) {
      this.dueDate = this.dueDateFromSprint;
    }

    if(this.startDateFromSprint) {
      this.startDate = this.startDateFromSprint;
    }
    this.service = new NorthStarService(this.$apollo);
  }
}


